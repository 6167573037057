<template>
  <div style="color: #161c2d;">
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>

    <SectionHeading classname="text-center" text="What is web3?"  isMargin="mb-3 mt-5"></SectionHeading>


    <section class="section pt-0" style="margin: 0 10px;">
      <div class="container" style="border-radius: 10px;">
        <h3>What is web3?</h3>
        <br/>
        <p>Web3 is the name given to the concept of a decentralized internet built on blockchain technology. If web 1.0 was the creation of the internet and web 2.0 saw us move to a social platform-centric internet, web3 signifies a shift into a decentralized, public internet centered on the concept of ownership.</p>
        <br/>
        <p>Web3, in essence, puts control and ownership back in the hands of the people using it, ideally creating a more equal playing field for users with less outside control from third parties. The term web3 has become shorthand for all of the elements that make up this ecosystem, including cryptocurrency, blockchain technology, decentralized finance (known as “DeFi”), NFTs, the metaverse, and decentralized apps (“dApps”).</p>
        <br/>

        <p>In 2014, Gavin Wood, co-founder of the Ethereum blockchain and the Web3 Foundation, coined the term in a blog post. He wrote:</p>
        <br/>
        <p>Web 3.0, or as might be termed the “post-Snowden” web, is a re-imagination of the sorts of things we already use the web for, but with a fundamentally different model for the interactions between parties. Information that we assume to be public, we publish. Information we assume to be agreed upon, we place on a consensus ledger. Information that we assume to be private, we keep secret and never reveal. </p>
        <br/>
        <p>His post focused on the four main components of what he imagined this third wave of the internet would have: “static content publication, dynamic messages, trustless transactions and an integrated user-interface.” Today, much of web3 is built on these principles. </p>
        <br/>

        <strong style="font-size:22px;">
          What were web 1.0 and web 2.0?
        </strong><br/><br>
        <p>When the internet reached mass adoption in the late ‘90s and early 2000s, it was predominantly used as a way to gain information through text. This internet, web 1.0, was filled with personal blogs, company websites, and message boards, and it relied on open protocols. Mostly, it was static, which is just a fancy way of saying that the sites in web 1.0 weren’t particularly interactive. Many refer to this as the “read-only” web.</p>
        <br/>
        <p>Web 2.0 was the next phase of the internet, which has been largely defined by a move to the mobile internet, and led by social media platforms and large financial institutions. Most of the internet is still using web 2.0’s platforms and institutions. If you’re wondering if you’re using web 2.0, ask yourself which apps you used to communicate with your friends and family today, and how you most recently checked the balance of your checking account. </p>
        <br/>
        <p>The advent of web 2.0 saw people co-creating content with the websites and apps they were using (think: filling out and customizing your social media presence). But even if you’ve customized your profile and have made it feel like it’s your own, the images, data, and information you share on these platforms belong to the corporations that own them — not to you. Many refer to web 2.0 as the “read-write” web.</p>
        <br/>
        <p>And this brings us to web3. In the ideal vision of web3, the ownership is no longer in the hands of large corporations. Instead, it’s firmly with the people using it. And this is facilitated by the decentralized nature of the blockchain that allows users to publicly, permanently, immutably record the creation and ownership of their items. This is now being referred to as the “read-write-own” web.</p>
        <br/>

        <strong style="font-size:22px;">
          What are the core principles of web3?
        </strong><br/><br>
        <p>First and foremost, it’s decentralized. Instead of being controlled by corporations and other large entities that can take users’ information and utilize it however they choose, web3 allows users to own their own information and items and distribute them how they see fit.</p>
        <br>
        <p>Remember when Gavin Wood cited “trustless transactions” as one of the most important aspects of the internet of the future? That wasn’t random. He was looking at web 1.0 and web 2.0 and seeing the trust users had to give to third parties in order to interact online, and he envisioned a world in which that was no longer necessary.</p>
        <br>
        <p>This is where blockchain technology comes in. The blockchain is basically a large, digital, public record. The most popular blockchains are distributed across many nodes (read: people’s computers), which is why you’ll hear them described as “decentralized.” So instead of a central company-owned server, the blockchain is distributed across a peer-to-peer network. This ensures that the blockchain remains immutable. And because the blockchain records and preserves history and acts as an unbiased party, transactions on it can be “trustless” in that they don’t require you to put your trust in someone in order to transact. Similarly, because the transactions are carried out by a network of computers, they are “permissionless” in that they don’t require the permission of a third party. </p>
        <br>
        <p>Web3’s use of cryptocurrency gives users an option outside of the traditional banking system. In reality, many still use a combination of government currency held by banks and cryptocurrency, but it still enables users to operate outside of traditional centralized systems. </p>
        <br>

        <strong style="font-size:22px;">
          How is web3 being used now?
        </strong><br/><br>
        <p>We will undoubtedly continue to see web3 expand and evolve over the coming years, but currently, its primary uses are primarily focused on financial transactions and ownership.</p>
        <br/>
        <strong style="font-size:18px;">
          NFTs
        </strong><br/><br>
        <p>An NFT (non-fungible token) is a unique digital item stored on a blockchain. NFTs can represent almost anything, and serve as a digital record of ownership. NFTs come in the form of art, profile pictures, collectibles, domain names, ticketing and memberships, games and gaming tools, virtual worlds, and the list is ever-changing. New and emerging use cases include identity verification, intellectual property, and storage solutions.</p>
        <br/>
        <strong style="font-size:18px;">
          Decentralized Finance (DeFi)
        </strong><br/><br>
        <p>Decentralized Finance, often shortened to simply “DeFi,” is the term used to describe all financial services that operate on blockchain technology. DeFi enables trustless, permissionless, and fast transactions. </p>
        <br/>

        <strong style="font-size:18px;">
          Cryptocurrency
        </strong><br/><br>
        <p>Cryptocurrency is digital currency backed by the blockchain. This allows transactions to be verified by a network of computers rather than a centralized entity or authority.</p>
        <br/>

        <strong style="font-size:18px;">
          DAOs
        </strong><br/><br>
        <p>DAOs — decentralized autonomous organizations — are organizations without sole leadership. Instead, they’re governed by the group of users who comprise the organization. Often, the users within a DAO will create proposals that are voted on to enact changes. DAOs have their own tokens that allow users to prove membership and vote. DAOs can have a wide range of purposes — anything from charitable giving to business networking to education.</p>
        <br/>

        <strong style="font-size:18px;">
          dApps
        </strong><br/><br>
        <p>dApps is a shortened form of “decentralized applications.” Unlike the apps utilized by web 2.0 that are owned by a single entity, dApps utilize blockchain technology, though they don’t necessarily need to be decentralized themselves. dApps can be operated via peer-to-peer network on the blockchain, or they can operate using traditional hierarchical structures, but what makes them dApps is their utilization of decentralized protocols.</p>
        <br/>

        <strong style="font-size:18px;">
          Metaverse
        </strong><br/><br>
        <p>The metaverse refers to a virtual-and-augmented reality in which users can interact with the digital space. The metaverse is actually less of a place and more of a modality — it describes the way users interact with the internet rather than the location in which it happens. In the metaverse, users can play games, customize their look, connect with other players, and communicate. It’s a new way for people to use VR and AR to socialize. While not all metaverses are or will be built on web3 technology, those that do allow their users to own their own goods within the metaverse.</p>
        <br/>


        <strong style="font-size:22px;">
          The future of web3
        </strong><br/><br>
        <p>Web3 is still a relatively new concept, and it will certainly continue to evolve throughout our lifetimes. It’s likely that the term “web3” will even feel outdated soon, as the elements described above become more widely-adopted and further integrated into our existing technologies. </p>
        <br/>
        <p>It’s probable that the future of web3 will bring innovations like improved digital storage (moving from centralized cloud providers to decentralized solutions), faster and more secure internet browsers, large scale changes in the way we interact with financial systems, improved online gaming and social experiences, new social networks that allow users to fully own their own data, and even new desktop and mobile operating systems.</p>
        <br/>







      </div>
    </section>



    <Footer classname="bg-cus on-dark"></Footer>
    <!--    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"-->
    <!--       v-scroll-to="'#topnav'">-->
    <!--      <arrow-up-icon class="icons"></arrow-up-icon>-->
    <!--    </a>-->
    <!-- Back to top -->
  </div>
</template>
<script>/**
 * Index-1
 */
import storage from "@/storage";
import HeaderMain from "@/components/n1/HeaderMain.vue";
import SectionHeading from "@/components/n1/SectionHeading.vue";
import Footer from "@/pages/n1/Footer.vue";

export default {
  name: "WhatIsWeb3",
  metaInfo: {
    title: 'GDMining',
    link: [
      // { rel: 'canonical', href: 'https://gdmining.com/what-is-web3' }
    ]
  },
  data() {
    return {
      data: [],
      login: false
    }
  },
  components:{
    HeaderMain,
    SectionHeading,
    Footer
  },
  created() {
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true;
    }
  }
};
</script>

<style scoped>
.bg-cus {
  background-color: #ffe5d0;
}
</style>



